import 'uno.css';
import '@unocss/reset/tailwind-compat.css';
import '@/assets/scss/main.scss';

import App from './app.vue';
import { createHead } from '@unhead/vue';
import { createI18n } from 'vue-i18n';
import { createPinia } from 'pinia';
import createRouter from '@/router';
import { createSSRApp } from 'vue';

const i18n = createI18n({
    // something vue-i18n options here ...
});

export function createApp() {
    const app = createSSRApp(App);
    const store = createPinia();
    const router = createRouter();
    const head = createHead();

    app.use(i18n);
    app.use(head as any);
    app.use(store).use(router);

    return { app, router, store, head };
}
