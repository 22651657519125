<template v-if="pageData">
    <HeroBanner :name="pageData?.name" />

    <section class="py-16 md:py-20">
        <div class="max-w-1320px w-[90%] mx-auto">
            <div v-if="pageData?.content" class="content" v-html="marked(pageData?.content)"></div>
            <div v-else role="status" class="animate-pulse">
                <div class="h-9 bg-gray-200 rounded-md bg-accent-lighten max-w-sm w-full mb-6"></div>
                <div class="h-6 bg-gray-200 rounded-md bg-accent-lighten w-full mb-3"></div>
                <div class="h-6 bg-gray-200 rounded-md bg-accent-lighten w-10/12 mb-4"></div>
                <div class="h-6 bg-gray-200 rounded-md bg-accent-lighten w-9/12 mb-4"></div>
            </div>
        </div>
    </section>

    <SectionStack v-if="route.params.slug === 'who-we-are' || !teamData" :data="teamData?.section">
        <template #content>
            <div class="grid sm:grid-cols-2 2xl:grid-cols-4 gap-y-20">
                <div v-for="item in teamData?.members" :key="item">
                    <div
                        class="flex flex-col items-center justify-center text-center sm:px-6 md:px-10 lg:px-16 xl:px-20 2xl:px-6">
                        <div class="relative w-200px h-200px mb-7">
                            <img
                                :src="item.photo?.url"
                                :alt="item.photo?.url"
                                class="absolute inset-0 w-full h-full object-cover rounded-full" />
                        </div>
                        <h4 class="text-2xl font-light text-tertiary mb-2">{{ item.first_name }} {{ item.last_name }}</h4>
                        <h5 class="text-base font-light text-neutral mb-3">{{ item.position }}</h5>
                        <p class="text-sm">
                            {{ item.bio }}
                        </p>

                        <!-- will use if needed -->
                        <!-- <div class="flex items-center gap-2">
                            <a href="/"
                                ><FacebookIcon
                                    class="w-5 h-5 fill-neutral transition-fill ease-[cubic-bezier(0.47,0,0.75,0.72)] duration-200 hover:fill-black"
                            /></a>
                            <a href="/"
                                ><TwitterIcon
                                    class="w-5 h-5 fill-neutral transition-fill ease-[cubic-bezier(0.47,0,0.75,0.72)] duration-200 hover:fill-black"
                            /></a>
                            <a href="/"
                                ><LinkedinIcon
                                    class="w-5.5 h-5.5 fill-neutral transition-fill ease-[cubic-bezier(0.47,0,0.75,0.72)] duration-200 hover:fill-black"
                            /></a>
                        </div> -->
                    </div>
                </div>
            </div>
        </template>
    </SectionStack>
</template>
<script setup lang="ts">
import { marked } from 'marked';
import { fetchAPI } from '@/utils/fetch-api';
import { useMemoize } from '@vueuse/core';
import { computed, onServerPrefetch, onUnmounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { ISEO } from '@/@types/seo';

//! Components
import SectionStack from '@/layouts/section-stack.vue';
import { useHead } from '@unhead/vue';

interface IProps {
    slug: string;
}

const props = withDefaults(defineProps<IProps>(), {
    slug: ''
});

const path = '/pages';

const route = useRoute();

const pageData = ref<any>({});
const teamData: any = ref(null);

const generateMetaTags = () => {
    return {
        title: () => pageData.value?.seo?.metaTitle || pageData.value?.name,
        meta: [
            {
                name: 'description',
                content: () => pageData.value?.seo?.metaDescription || pageData.value?.name
            }
        ]
    };
};

const metaTags = computed(generateMetaTags);

type Page = {
    name: string;
    slug: string;
    content: string;
    seo: ISEO;
};

async function init() {
    const path = '/about-block';

    try {
        const data = await fetchAPI(path);

        if (data !== null) teamData.value = data;
    } catch (e) {
        console.log(e);
    }
}

const getPageData = useMemoize(async (slug: string | string[]): Promise<Page> => {
    try {
        const response = await fetchAPI(`${path}/${slug}`);
        return response.data;
    } catch (e) {
        console.log(e);
        throw e; // Rethrow the error to handle it in the calling function
    }
});

async function fetchSEO() {
    let data = await fetchAPI(`${path}/${props.slug}`);

    if (data?.data.seo !== null) {
        data?.data.seo?.metaTag.map((el: { attribute: string; title: any; content: any }) => {
            const name = el.attribute !== 'property' ? el.title : null;
            const metaTag: { [key: string]: any; name: any; content: () => any } = {
                [el.attribute]: el.title,
                name,
                content: () => el.content
            };

            metaTags.value.meta.push(metaTag);
        });
    }
}

watch(
    () => route.params.slug,
    async (res) => {
        if (res) {
            try {
                let data = await getPageData(res);
                if (data !== null) {
                    pageData.value = data;
                }
            } catch (e) {
                console.log(e);
            }
        }
    },
    { immediate: true }
);

init();

onServerPrefetch(async () => {
    fetchSEO();

    useHead(metaTags.value);
});

onUnmounted(() => {
    pageData.value = {};
});
</script>
