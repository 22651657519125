export function getStrapiMedia(url: string | undefined) {
    if (url === undefined) return undefined;

    // Return the full URL if the media is hosted on an external provider
    if (url?.startsWith('http') || url?.startsWith('//')) return url;

    // Otherwise prepend the URL path with the Strapi URL
    return `${import.meta.env.VITE_API_URL}${url}`;
}

export function formatDate(dateString: string) {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
}

export function getErrorImg(e: any) {
    e.target.style.display = 'none';
}
