<template>
    <div v-if="true" class="relative flex flex-col justify-between min-h-screen">
        <Header :data="navigation?.header_nav" />
        <main v-bind="$attrs" class="flex-1">
            <router-view v-slot="{ Component }" :key="$route.fullPath">
                <Suspense>
                    <template v-if="Component">
                        <component :is="Component" />
                    </template>

                    <template #fallback> Loading... </template>
                </Suspense>
            </router-view>
        </main>
        <Footer v-if="navigation?.footer_nav" :data="navigation?.footer_nav" />
    </div>
    <maintenance v-else />
</template>
<script setup lang="ts">
//! Plugins
import { useHead } from '@unhead/vue';

import Header from '@/template/header.vue';
import Footer from '@/template/footer.vue';
import Maintenance from '@/layouts/maintenance.vue';
import { fetchAPI } from '@/utils/fetch-api';
import { computed, onErrorCaptured, onServerPrefetch, ref } from 'vue';

const navigation: any = ref(null);
const path = '/global';

const generateMetaTags = () => {
    return {
        meta: [
            {
                property: 'og:image',
                content: () => navigation.value?.seo.metaImage?.formats?.thumbnail.url
            },
            {
                property: 'og:image:width',
                content: () => navigation.value?.seo.metaImage?.formats?.thumbnail.width
            },
            {
                property: 'og:image:height',
                content: () => navigation.value?.seo.metaImage?.formats?.thumbnail.height
            },
            {
                property: 'og:image:type',
                content: () => navigation.value?.seo.metaImage.mime
            },
            {
                property: 'og:url',
                content: () => navigation.value?.seo.canonicalURL
            },
            {
                property: 'og:type',
                content: 'website'
            },
            {
                name: 'twitter:image',
                content: () => navigation.value?.seo.metaImage?.formats?.thumbnail.url
            },
            {
                name: 'twitter:image:width',
                content: () => navigation.value?.seo.metaImage?.formats?.thumbnail.width
            },
            {
                name: 'twitter:image:height',
                content: () => navigation.value?.seo.metaImage?.formats?.thumbnail.height
            },
            {
                name: 'twitter:image:type',
                content: () => navigation.value?.seo.metaImage.mime
            }
        ]
    };
};

const metaTags = computed(generateMetaTags);

async function init() {
    try {
        const { seo, top_navigation, bottom_navigation } = await fetchAPI(path);

        navigation.value = {
            seo,
            header_nav: top_navigation.navigation_items,
            footer_nav: bottom_navigation.navigation_items
        };
    } catch (e) {
        console.log(e);
    }
}

async function fetchSEO() {
    const { seo } = await fetchAPI(path);

    if (seo !== null) {
        seo?.metaTag.map((el: { attribute: string; title: any; content: any }) => {
            const name = el.attribute !== 'property' ? el.title : null;
            const metaTag: { [key: string]: any; name: any; content: () => any } = {
                [el.attribute]: el.title,
                name,
                content: () => el.content
            };

            metaTags.value.meta.push(metaTag);
        });
    }
}

init();

onErrorCaptured((err) => {
    console.log(err);
});

onServerPrefetch(async () => {
    init();
    fetchSEO();

    useHead(metaTags.value);
});
</script>

<style scoped lang="scss"></style>
