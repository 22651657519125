<template>
    <section class="relative z-100 bg-tertiary">
        <div
            class="mx-auto"
            :class="router.currentRoute.value.path === '/' ? 'w-[90%] lg:w-full' : 'max-w-1320px w-[90%] pt-24 md:pt-32 pb-32'">
            <div v-if="router.currentRoute.value.path === '/'" class="lg:flex flex-wrap">
                <div class="lg:w-7/12">
                    <div class="lg:px-35px 3xl:pl-135px py-16 sm:py-24 md:py-32 lg:py-180px">
                        <div v-if="data.subtitle && data.title && data.description" class="lg:max-w-525px xl:max-w-650px w-full">
                            <span class="subtitle font-bold mb-3">{{ data.subtitle }}</span>

                            <h1 class="text-4xl md:text-5xl leading-[1.2] font-light text-left text-white mb-5">
                                {{ data.title }}
                            </h1>

                            <p class="text-left text-base md:text-lg text-#d1d1d1 font-light leading-[1.8]">
                                {{ data.description }}
                            </p>
                        </div>
                        <div v-else role="status" class="animate-pulse">
                            <div class="h-6 bg-gray-200 rounded-full bg-accent-lighten max-w-xs w-full mb-4"></div>
                            <div class="h-12 bg-gray-200 rounded-full bg-accent-lighten max-w-xl w-full mb-6"></div>
                            <div class="h-4 bg-gray-200 rounded-full bg-accent-lighten max-w-2xl w-full mb-3"></div>
                            <div class="h-4 bg-gray-200 rounded-full bg-accent-lighten max-w-lg w-full mb-3"></div>
                            <div class="h-4 bg-gray-200 rounded-full bg-accent-lighten max-w-md w-full"></div>
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
                <div class="lg:w-5/12 hidden lg:block">
                    <div class="relative h-full bg-#ddd overflow-hidden rounded-tl-[290px_10%] rounded-bl-[290px_10%]">
                        <img
                            :src="getStrapiMedia(data.media?.url)"
                            fetchpriority="high"
                            class="absolute inset-0 w-full h-full object-cover"
                            @error="getErrorImg" />
                    </div>
                </div>
            </div>
            <div v-else class="text-center">
                <h1 v-if="name" class="text-4xl md:text-45px text-white leading-none font-thin">{{ name }}</h1>
                <div v-else role="status" class="animate-pulse max-w-xs w-full mx-auto">
                    <div class="h-45px bg-gray-200 rounded-full bg-accent-lighten mb-4"></div>
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>

        <div class="md:block hidden">
            <div class="absolute top-12% left-3% -z-100">
                <img
                    src="/shape-1.png"
                    alt="/shape-1.png"
                    class="animate-name-movebounce animate-duration-5000 animate-ease-linear animate-iteration-infinite" />
            </div>
            <div
                class="absolute -z-100"
                :class="
                    router.currentRoute.value.path === '/'
                        ? 'top-10% right-8% lg:top-13% lg:left-30%'
                        : 'top-10% right-8% left-auto'
                ">
                <img
                    src="/shape-2.png"
                    alt="/shape-2.png"
                    class="animate-name-moveleftbounce animate-duration-5000 animate-ease-linear animate-iteration-infinite w-60px" />
            </div>
            <div
                class="absolute bottom-10% z-200"
                :class="router.currentRoute.value.path === '/' ? 'left-47%' : 'left-50% -translate-x-2/4'">
                <img
                    src="/shape-2.png"
                    alt="/shape-2.png"
                    class="animate-name-movescale animate-duration-15000 animate-ease-linear animate-iteration-infinite" />
            </div>
            <div class="absolute bottom-10% left-9% -z-100">
                <img
                    src="/shape-3.png"
                    alt="/shape-3.png"
                    class="animate-name-animationFramesOne animate-duration-20000 animate-ease-linear animate-iteration-infinite" />
            </div>
            <div
                class="absolute -z-100"
                :class="
                    router.currentRoute.value.path === '/'
                        ? 'bottom-55% left-40% lg:bottom-17% lg:left-25%'
                        : 'bottom-55% left-40%'
                ">
                <img
                    src="/shape-4.png"
                    alt="/shape-4.png"
                    class="animate-name-rotateme animate-duration-30000 animate-ease-linear animate-iteration-infinite" />
            </div>
            <div class="absolute bottom-8% left-35% -z-100">
                <img
                    src="/shape-5.png"
                    alt="/shape-5.png"
                    class="animate-name-rotateme animate-duration-20000 animate-ease-linear animate-iteration-infinite" />
            </div>
            <div
                class="absolute bottom-22% -z-100"
                :class="router.currentRoute.value.path === '/' ? 'left-90% lg:left-38%' : 'left-90%'">
                <img
                    src="/shape-6.png"
                    alt="/shape-6.png"
                    class="animate-name-moveleftbounce animate-duration-5000 animate-ease-linear animate-iteration-infinite" />
            </div>
            <div class="absolute bottom-20% left-15% -z-100">
                <img
                    src="/shape-7.png"
                    alt="/shape-7.png"
                    class="animate-name-rotateme animate-duration-20000 animate-ease-linear animate-iteration-infinite" />
            </div>
        </div>

        <div class="absolute top-0 left-0 -z-100">
            <img src="/shape-8.png" alt="/shape-8.png" class="max-w-350px w-full" />
        </div>
    </section>
</template>
<script setup lang="ts">
import { useRouter } from 'vue-router';
import { getStrapiMedia, getErrorImg } from '@/utils/helpers';

interface IProps {
    name?: string;
    data?: {
        title: string;
        subtitle: string;
        description?: string;
        media: {
            url: string;
        };
    };
}

withDefaults(defineProps<IProps>(), {
    name: '',
    data: () => {
        return {
            title: '',
            subtitle: '',
            description: '',
            media: {
                url: ''
            }
        };
    }
});

const router = useRouter();
</script>
<style lang="scss"></style>
