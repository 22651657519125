<template>
    <footer v-show="!$route.meta.hideFooter" class="relative bg-#100f1f">
        <div class="max-w-1320px w-[90%] mx-auto">
            <div
                class="grid grid-flow-row-dense md:grid-cols-[auto_1fr] lg:grid-cols-[repeat(auto-fit,_minmax(250px,_1fr))] gap-x-16 lg:gap-x-10 gap-y-10 py-16 md:py-20 lg:py-24">
                <div class="flex lg:flex-col gap-y-10 md:col-span-2 lg:col-span-1">
                    <router-link to="/" class="block w-120px">
                        <LogoIcon />
                    </router-link>

                    <ul class="flex gap-x-5px ml-auto lg:ml-0">
                        <li>
                            <a
                                href="https://www.linkedin.com/company/backupcto/"
                                class="relative flex items-center justify-center bg-#1e1d2f rounded-sm text-white w-8 h-8 text-center transition-background-color ease-[cubic-bezier(0.46,0.03,0.52,0.96)] duration-200 hover:text-white hover:bg-primary"
                                target="_blank">
                                <LinkedInIcon class="w-3.5 h-3.5 fill-current" />
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://twitter.com/backupcto"
                                class="relative flex items-center justify-center bg-#1e1d2f rounded-sm text-white w-8 h-8 text-center transition-background-color ease-[cubic-bezier(0.46,0.03,0.52,0.96)] duration-200 hover:text-white hover:bg-primary"
                                target="_blank">
                                <TwitterIcon class="w-3.5 h-3.5 fill-current" />
                            </a>
                        </li>
                    </ul>
                </div>

                <div v-for="route in data" :key="route.id">
                    <h3 class="text-white mb-3 md:mb-5 text-xl md:text-2xl font-light">
                        {{ route.name }}
                    </h3>
                    <ul class="text-#ededed text-base md:text-lg font-extralight">
                        <li
                            v-for="item in route.navigation_links"
                            :key="item.internalUrl"
                            class="flex items-start gap-x-2 md:gap-x-3 lg:gap-x-2"
                            :class="{
                                'mb-1.5 md:mb-3':
                                    route.navigation_links[route.navigation_links.length - 1].internalUrl !== item.internalUrl
                            }">
                            <img
                                v-if="item.icon"
                                :src="item.icon.url"
                                :alt="item.icon?.name"
                                class="w-18px h-18px fill-primary"
                                :class="{
                                    'mt-1':
                                        route.navigation_links[route.navigation_links.length - 1].internalUrl !== item.internalUrl
                                }" />
                            <span
                                v-if="item.internalUrl === null && item.externalUrl === null"
                                class="flex-1 pointer-events-none"
                                >{{ item.name }}</span
                            >
                            <a
                                v-else-if="item.externalUrl"
                                rel="noopener noreferrer nofollow"
                                :target="item.newTab ? '_blank' : ''"
                                :href="item.externalUrl"
                                >{{ item.name }}</a
                            >

                            <router-link
                                v-if="item.internalUrl !== null"
                                :to="{
                                    name: 'page',
                                    params: { slug: item.internalUrl }
                                }"
                                class="flex-1">
                                {{ item.name }}
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="text-center md:text-left md:text-lg text-#F8F9FA border-t border-solid border-#181726 py-6">
                &copy; {{ currentYear }} BackupCTO B.V.
            </div>
        </div>
        <div class="absolute bottom-0 right-0">
            <img src="/shape-9.png" alt="shape-9.png" />
        </div>
    </footer>
</template>
<script setup lang="ts">
//! Icons
// Logo
import LogoIcon from '@/assets/icons/logo-light.svg';

// Socials
import LinkedInIcon from '@/assets/icons/linkedin.svg';
import TwitterIcon from '@/assets/icons/twitter.svg';

interface IProps {
    data: [
        {
            id: string | number;
            name: string;
            internalUrl: string;
            navigation_links: [
                {
                    id: string | number;
                    name: string;
                    newTab: boolean;
                    internalUrl: string;
                    externalUrl: string;
                    icon: {
                        url: string;
                        name: string;
                    };
                }
            ];
        }
    ];
}

withDefaults(defineProps<IProps>(), {
    data: (): IProps['data'] => {
        return [
            {
                id: '',
                name: '',
                internalUrl: '',
                navigation_links: [
                    {
                        id: '',
                        name: '',
                        newTab: false,
                        internalUrl: '',
                        externalUrl: '',
                        icon: {
                            url: '',
                            name: ''
                        }
                    }
                ]
            }
        ];
    }
});

const date = new Date();
const currentYear = date.getFullYear();
</script>
<style lang="scss"></style>
