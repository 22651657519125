<template>
    <div class="py-16 md:pt-14 md:pb-20">
        <div class="max-w-1320px w-[90%] mx-auto">
            <div v-if="Object.values(postData).length === 0" role="status" class="animate-pulse">
                <div class="grid grid-cols-2 gap-x-14 mb-14">
                    <div>
                        <div class="h-8 bg-gray-200 rounded-md bg-accent-lighten w-full mb-2"></div>
                        <div class="h-8 bg-gray-200 rounded-md bg-accent-lighten w-3/6 mb-6"></div>
                        <div class="h-4 bg-gray-200 rounded-md bg-accent-lighten w-full mb-2"></div>
                        <div class="h-4 bg-gray-200 rounded-md bg-accent-lighten w-5/6 mb-2"></div>
                        <div class="h-4 bg-gray-200 rounded-md bg-accent-lighten w-4/6 mb-2"></div>
                    </div>
                    <div class="h-400px bg-gray-200 rounded-md bg-accent-lighten w-full mb-4"></div>
                </div>

                <div class="h-4 bg-gray-200 rounded-md bg-accent-lighten w-full mb-2"></div>
                <div class="h-4 bg-gray-200 rounded-md bg-accent-lighten w-5/6 mb-2"></div>
                <div class="h-4 bg-gray-200 rounded-md bg-accent-lighten w-4/6 mb-2"></div>
            </div>

            <div v-else>
                <div class="grid grid-cols-2 gap-x-14 mb-14">
                    <div class="pt-2">
                        <div v-if="postData?.author" class="flex items-center gap-x-2.5 mb-6">
                            <div class="relative w-14 h-14 bg-gray-200 rounded-full">
                                <img
                                    v-if="postData?.author.avatar?.url"
                                    :src="postData?.author.avatar?.url"
                                    class="absolute inset-0 w-full h-full object-cover rounded-inherit" />
                            </div>
                            <div>
                                <div class="text-base font-medium">By {{ postData?.author?.name }}</div>
                                <div class="text-xs text-neutral">Posted on {{ postData?.date }}</div>
                            </div>
                        </div>
                        <h1 v-if="postData?.title" class="text-3xl font-bold mb-6">{{ postData?.title }}</h1>
                        <p v-if="postData?.description" class="text-lg leading-34px mb-5">
                            {{ postData?.description }}
                        </p>
                    </div>
                    <div>
                        <div class="relative overflow-hidden bg-gray-200 rounded-3xl min-h-[400px] mb-4">
                            <img
                                v-if="postData?.thumbnail?.url"
                                :src="postData?.thumbnail?.url"
                                class="absolute inset-0 w-full h-full object-cover" />
                        </div>
                        <router-link to="/blog" class="btn btn-outline w-full">Back to overview</router-link>
                    </div>
                </div>

                <div v-if="postData?.content" class="blog-content pt-14 border-t border-solid border-#e5e5e5">
                    <div class="md" v-html="marked(postData?.content)"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { ref } from 'vue';

const path = '/blogs';
const postData: any = ref({});

export default {
    async beforeRouteEnter(to, _from, next) {
        let cachedData: any = null;

        const fetchData = async () => {
            try {
                const data = await fetchAPI(path);
                cachedData = data;
                return data;
            } catch (e) {
                console.log(e);
            }
        };

        let data = cachedData || (await fetchData());

        let matchSlug = data?.find((el: any) => el.slug === to.params.slug);

        if (!!matchSlug) {
            postData.value = matchSlug;
            next();
        } else {
            postData.value = null;
            next({ name: 'not-found', replace: true, params: { pathMatch: to.path.substring(1).split('/') } });
        }
    }
};
</script>
<script setup lang="ts">
import { fetchAPI } from '@/utils/fetch-api';
import { useMemoize } from '@vueuse/core';
import { computed, onBeforeUnmount, onServerPrefetch } from 'vue';
import { useRoute } from 'vue-router';
import { useHead } from '@unhead/vue';
import { marked } from 'marked';

const route = useRoute();

const generateMetaTags = () => {
    return {
        title: () => postData.value.title,
        meta: [
            {
                name: 'description',
                content: () => postData.value.description
            },
            {
                property: 'og:title',
                content: () => postData.value.title
            },
            {
                property: 'og:description',
                content: () => postData.value.description
            },
            {
                property: 'og:image',
                content: () => postData.value.thumbnail?.formats?.thumbnail.url
            },
            {
                property: 'og:image:width',
                content: () => postData.value.thumbnail?.formats?.thumbnail.width
            },
            {
                property: 'og:image:height',
                content: () => postData.value.thumbnail?.formats?.thumbnail.height
            },
            {
                property: 'og:image:type',
                content: () => postData.value.thumbnail?.mime
            },
            {
                property: 'og:url',
                content: () => `https://staging.backupcto.com/blog/post/${route.params.slug}`
            },
            {
                property: 'og:type',
                content: 'article'
            },
            {
                name: 'twitter:title',
                content: () => postData.value.title
            },
            {
                name: 'twitter:description',
                content: () => postData.value.description
            },
            {
                name: 'twitter:image',
                content: () => postData.value.thumbnail?.formats?.thumbnail.url
            },
            {
                name: 'twitter:image:width',
                content: () => postData.value.thumbnail?.formats?.thumbnail.width
            },
            {
                name: 'twitter:image:height',
                content: () => postData.value.thumbnail?.formats?.thumbnail.height
            },
            {
                name: 'twitter:image:type',
                content: () => postData.value.thumbnail?.mime
            }
        ]
    };
};
const metaTags = computed(generateMetaTags);
useHead(metaTags.value);

const getPageData = useMemoize(async (slug: string | string[] | undefined): Promise<object[]> => {
    try {
        const response = await fetchAPI(`${path}/${slug}`);
        return response.data;
    } catch (e) {
        console.log(e);
        throw e; // Rethrow the error to handle it in the calling function
    }
});

//? will use if needed
// watch(
//     () => route.params.slug,
//     async (value) => {
//         if (value) {
//             try {
//                 let data = await getPageData(value);

//                 if (data !== null) postData.value = data;
//             } catch (e) {
//                 console.log(e);
//             }
//         }
//     },
//     { immediate: true }
// );

onServerPrefetch(async () => {
    getPageData(route.params.slug);

    useHead(metaTags.value);
});

onBeforeUnmount(() => {
    postData.value = null;
});
</script>
<style lang="scss"></style>
