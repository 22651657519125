<template>
    <HeroBanner :name="blogPageData.title" />
    <div class="bg-#f9f9f9 py-16 md:py-20">
        <div v-if="!errState" class="max-w-1320px w-[90%] mx-auto">
            <div v-if="blogListData.length > 0" class="grid grid-cols-2 gap-8">
                <article
                    v-for="item in blogListData"
                    :key="item.id"
                    class="relative flex flex-col h-full rounded-2xl border border-solid border-#e5e5e5">
                    <figure class="relative bg-gray-200 h-300px rounded-t-2xl overflow-hidden">
                        <img
                            v-if="item?.thumbnail?.url"
                            class="absolute inset-0 w-full h-full object-cover"
                            :src="item?.thumbnail?.url" />
                    </figure>
                    <div class="relative flex flex-col flex-1 bg-white p-8 rounded-b-2xl">
                        <span
                            v-if="item?.date"
                            class="absolute -top-3.5 right-7 flex items-center justify-center w-auto px-3 bg-white border border-solid border-#e5e5e5 shadow-[-1px_3px_10px_0_rgba(0,0,0,6%)] rounded-10px min-h-28px tracking-wider text-center text-sm"
                            >{{ formatDistance(new Date(item?.date), new Date()) }}</span
                        >
                        <div class="mb-6">
                            <h2 class="text-xl line-clamp-2 leading-24px font-bold mb-2">
                                {{ item?.title }}
                            </h2>
                            <div v-if="item.author?.name" class="flex items-center gap-x-2">
                                By
                                <div v-if="item.author?.avatar?.url" class="relative w-5 h-5 bg-gray-200 rounded-full">
                                    <img
                                        :src="item.author?.avatar?.url"
                                        class="absolute inset-0 w-full h-full object-cover rounded-inherit" />
                                </div>
                                <div class="text-sm">{{ item.author?.name }}</div>
                            </div>
                            <p class="text-base font-normal line-clamp-2 text-#212529 pt-4">
                                {{ item?.description }}
                            </p>
                        </div>
                        <div class="mt-auto">
                            <router-link
                                :to="{ name: 'post', params: { slug: item?.slug } }"
                                class="flex items-center text-primary text-base">
                                Read more
                                <svg
                                    class="fill-primary w-18px h-18px ml-2 rotate-90"
                                    width="512"
                                    height="512"
                                    enable-background="new 0 0 512 512"
                                    version="1.1"
                                    viewBox="0 0 32 32"
                                    xml:space="preserve"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M24.707 9.707a1 1 0 0 1-1.414 0L17 3.414V31a1 1 0 0 1-2 0V3.414L8.707 9.707a1 1 0 1 1-1.414-1.414l8-8a1 1 0 0 1 1.414 0l8 8a1 1 0 0 1 0 1.414z"
                                        data-name="Up" />
                                </svg>
                            </router-link>
                        </div>
                    </div>
                    <router-link :to="{ name: 'post', params: { slug: item?.slug } }" class="stretched-link">
                        <span class="hidden">Read more</span>
                    </router-link>
                </article>
            </div>
            <div v-else class="grid grid-cols-2 gap-8">
                <div v-for="n in 8" :key="n" role="status" class="animate-pulse" :data-id="n">
                    <div class="h-300px bg-gray-200 rounded-md bg-accent-lighten w-full mb-4"></div>
                    <div class="h-5 bg-gray-200 rounded-md bg-accent-lighten w-full mb-2"></div>
                    <div class="h-5 bg-gray-200 rounded-md bg-accent-lighten w-2/4 mb-4"></div>
                    <div class="h-5 bg-gray-200 rounded-md bg-accent-lighten w-full mb-2"></div>
                    <div class="h-5 bg-gray-200 rounded-md bg-accent-lighten w-full mb-2"></div>
                </div>
            </div>
        </div>
        <div v-else>
            <h2 class="text-center">Something went wrong, please try again later</h2>
        </div>
    </div>
</template>
<script setup lang="ts">
import { fetchAPI } from '@/utils/fetch-api';
import { useHead } from '@unhead/vue';
import { computed, onBeforeUnmount, onServerPrefetch, ref } from 'vue';
import { formatDistance } from 'date-fns';

const blogPageData = ref<any>({});
const blogListData = ref<any[]>([]);
const errState = ref<boolean>(false);

const generateMetaTags = () => {
    return {
        title: () => blogPageData.value?.seo?.metaTitle || blogPageData.value?.title,
        meta: [
            {
                name: 'description',
                content: () => blogPageData.value?.seo?.metaDescription || blogPageData.value?.description
            }
        ]
    };
};

const metaTags = computed(generateMetaTags);

async function init() {
    try {
        const blogPage = await fetchAPI('/blog-page');
        const blogList = await fetchAPI('/blogs');

        if (blogPage !== null || blogList !== null) {
            blogPageData.value = blogPage;
            blogListData.value = blogList;

            if (blogPage.seo !== null) {
                blogPage.value.seo.metaTag.map((el: { attribute: string; title: any; content: any }) => {
                    const name = el.attribute !== 'property' ? el.title : null;
                    const metaTag: { [key: string]: any; name: any; content: () => any } = {
                        [el.attribute]: el.title,
                        name,
                        content: () => el.content
                    };

                    metaTags.value.meta.push(metaTag);
                });
            }

            errState.value = false;
        } else {
            errState.value = true;
        }
    } catch (e) {
        errState.value = true;
    }
}

init();

onServerPrefetch(async () => {
    init();

    useHead(metaTags.value);
});

onBeforeUnmount(() => {
    blogPageData.value = [];
    blogListData.value = [];
});
</script>
