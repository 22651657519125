import Axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';

const axiosWithCache = Axios as any;
const token = import.meta.env.VITE_PUBLIC_STRAPI_API_TOKEN;
const apiUrl = import.meta.env.VITE_API_URL;
const url = import.meta.env.MODE === 'staging' || import.meta.env.MODE === 'production' ? apiUrl : '/api';

const instance = Axios.create({
    baseURL: url,
    maxRedirects: 0,
    headers: { Authorization: token }
});

const axios = axiosWithCache.defaults.cache ? instance : setupCache(instance);
const controller = new AbortController();

export async function fetchAPI(path: string = '', params: object = {}) {
    try {
        const { data } = await axios.get(path, { signal: controller.signal, params });

        return data;
    } catch (error: unknown) {
        if (error instanceof Error) throw new Error(error.message);
    }
}

export async function fetchSvgUrl(url: string = '') {
    const res = await axios.get(url);

    return res.data;
}
