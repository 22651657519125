import BlogList from '@/pages/blog/list.vue';
import BlogPost from '@/pages/blog/post.vue';
import Page from '@/pages/page.vue';
import { RouteRecordRaw } from 'vue-router';
import { fetchAPI } from '@/utils/fetch-api';

const validatePage = () => {
    let cachedData: any = null;

    const fetchData = async () => {
        try {
            const path = `/pages`;
            const data = await fetchAPI(path);
            cachedData = data;
            return data;
        } catch (e) {
            console.log(e);
        }
    };

    const beforeEnter = async (to: any) => {
        let data = cachedData || (await fetchData());

        let matchSlug = data?.find((el: any) => el.slug === to.params.slug);

        if (!!matchSlug === false) {
            return { name: 'not-found', replace: true, params: { pathMatch: to.path.substring(1).split('/') } };
        }
    };

    return beforeEnter;
};

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'home',
        meta: {
            scrollPos: {
                top: 0,
                left: 0
            }
        },
        component: () => import('@/pages/index.vue')
    },
    //! Dynamic route
    {
        path: '/:slug',
        name: 'page',
        props: true,
        component: Page,
        beforeEnter: validatePage()
    },
    {
        path: '/model/:slug',
        name: 'model',
        component: Page,
        beforeEnter: validatePage()
    },
    {
        path: '/blog',
        component: () => import('@/pages/blog/index.vue'),
        children: [
            {
                path: '',
                name: 'blog',
                component: BlogList
            },
            {
                path: 'post/:slug',
                name: 'post',
                component: BlogPost
            }
        ]
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import('@/pages/contact.vue')
    },
    //? will use if needed
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: () => import('@/pages/404.vue'),
        meta: { hideFooter: true }
    },
    {
        path: '/404',
        name: '404',
        component: () => import('@/pages/404.vue'),
        meta: { hideFooter: true }
    }
];

export default routes;
