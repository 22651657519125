//? will use if needed
// import * as Sentry from '@sentry/vue';

import Rollbar from 'rollbar';
import { createApp } from './main';

const { app, router, store } = createApp();

interface Location {
    [x: string]: any;
    assign(href: string | URL): void;
}

if (window.__INITIAL_STATE__) {
    store.state.value = JSON.parse(JSON.stringify(window.__INITIAL_STATE__));

    router.onError((error, to) => {
        let location: Location = window.location;
        if (
            error.message.includes('Failed to fetch dynamically imported module') ||
            error.message.includes('Importing a module script failed')
        ) {
            if (!to?.fullPath) {
                location.reload();
            } else {
                window.location.href = to.fullPath;
            }
        }
    });

    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register(import.meta.env.MODE === 'production' ? './sw.js' : '/dev-sw.js?dev-sw');
    }
}

//? will use if needed
// Sentry.init({
//     app,
//     dsn: 'https://b532a91690be26b69aeebeaf9a29f3a1@o4505991044988928.ingest.sentry.io/4505991175602176',
//     integrations: [
//         new Sentry.BrowserTracing({
//             // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//             tracePropagationTargets: [/^https:\/\/backupcto\.com\//, /^https:\/\/staging.backupcto\.com\//],
//             routingInstrumentation: Sentry.vueRouterInstrumentation(router)
//         }),
//         new Sentry.Replay()
//     ],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const rollbar = new Rollbar({
    accessToken: '366b05c047ce4a1da018b82fdd2ab771',
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
        environment: 'dev',
        client: {
            javascript: {
                code_version: '1.0.0'
            }
        }
    }
});

app.config.errorHandler = (err: any, vm, info) => {
    rollbar.error(err, { vueComponent: vm, info });
    throw err; // rethrow
};

app.provide('rollbar', rollbar);

router.isReady().then(() => app.mount('#app'));
